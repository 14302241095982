/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        'chci_pomoci': {
            root_url: "https://api.paylibo.com/paylibo/generator/czech/image",
            createQR: function (amount, accountNr, bankCode, vr) {

                var date = new Date();
                date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
                var dateMsg = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDay();

                var params = $.param({
                    //  'accountPrefix':'',
                    'accountNumber': accountNr,
                    'bankCode': bankCode,
                    'amount': amount,
                    'currency': 'CZK',
                    'recipientName': 'RAFAEL DĚTEM, nadační fond',
                    'identifier': '02804140',
                    'vs': '02804140',
                    // 'date' : dateMsg,
                    'message': 'Nadační příspěvek Rafael dětem',
                });
                // console.log(params);
                var imgUrl = Sage.chci_pomoci.root_url + "?" + params;
                return imgUrl;
            },
            init: function () {
                var amount;
                var transaction_id = 0;
                $('.donation-form-outer input[name="sel-amount"]').change(function (e) {
                    amount = $(this).data('amount');
                    if ($('#pay-bank').is(':visible')) {
                        $('#pokracovatBtn').trigger('click');
                    }
                });

                $('#pokracovatBtn').on('click', function (e) {
                    e.preventDefault();

                    var other_amount = $('.donation-form-outer input[name="other-amount"]').val();
                    if (other_amount && (other_amount != 0)) {
                        var reg = new RegExp('^[0-9]+$');
                        if (reg.test(other_amount)) {
                            if (other_amount > 50) {
                                amount = other_amount;
                                $('.donation-form-outer input[name="other-amount"]').removeClass('error');
                            } else {
                                $('.donation-form-outer input[name="other-amount"]').addClass('error');
                                alert('Hodnota daru musí být větší než 50kč');
                            }
                        } else {
                            $('.donation-form-outer input[name="other-amount"]').addClass('error');
                            alert('Zadejte, prosím, částku jako celé číslo');
                        }
                    } else {
                        amount = $('.donation-form-outer input[name="sel-amount"]:checked').data('amount');
                    }

                    $('#amount-to-pay').text(amount);
                    var imgUrl = Sage.chci_pomoci.createQR(amount, '214203612', '0600', 0);
                    $('#qr_platba').attr('src', imgUrl);
                    /* if (transaction_id === 0) {
                     $.get('/wp-admin/admin-ajax.php', {'action': 'chsoft_create_transaction'}, function (r) {
                     if (r.id) {
                     transaction_id = r.id;                                
                     
                     // $('#platba-kartou-link').attr('href',UTIL.createTransactionLink(transaction_id,amount));
                     }
                     }, 'json');
                     }*/
                    $('#pay-bank').slideDown(500);

                });

                $('#donate-popup').on('show.bs.modal', function () {
                    if (transaction_id === 0) {
                        $.get('/wp-admin/admin-ajax.php', {'action': 'chsoft_create_transaction'}, function (r) {
                            if (r.id) {
                                transaction_id = r.id;
                                $('#order_id_input').val(r.id);
                                $('#amount-label span').text(amount);
                                $('#amount_input').val(amount);
                            }
                        }, 'json');
                    }
                });

                $('#payu_form_submit').on('click', function (e) {
                    e.preventDefault();
                     $.get('/wp-admin/admin-ajax.php', {'action': 'chsoft_create_payment_redirect','data': $('#payu_form').serialize()}, function (r) {
                         if(r && (r.status == 'ok')){
                             window.location.href = r.url;
                         }
                     },'json');
                });
                if(UTIL.getUrlParameter('rozbalit')){
                    $('#pokracovatBtn').trigger('click');
                    $('html, body').animate({
                        scrollTop: 70
                    }, 2000);
                }
            }

        }
    }
    

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
        var UTIL = {
            fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

                if (fire) {
            namespace[func][funcname](args);
        }
        },
            loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
                $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
            UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
        UTIL.fire('common', 'finalize');
        },
            createTransactionLink: function (tid, amount) {
        return '/platba-kartou/?amount=' + amount + '&tid=' + tid;
    },  
    getUrlParameter: function(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
    }    
    
    
    };

    // Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
